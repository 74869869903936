const config = {
  STRIPE_KEY: "pk_test_coyuSWnBY3TLSSyQknagFaVX",
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-upload.jackbennett.co",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://qqu3ydwxx6.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_JFM0vcYla",
    APP_CLIENT_ID: "5fqgp2r42elegqj0e77eng0n62",
    IDENTITY_POOL_ID: "us-east-1:543fb98d-c784-4e86-984f-dccd4c4477d9",
  },
};

export default config;